<template>
  <div class="container">
    <Header>
      <template v-slot:title>
        <span>东方美景预订平台</span>
      </template>
    </Header>

    <div class="pictures">
      <el-carousel indicator-position="outside" height="500px">
        <el-carousel-item v-for="(image, index) in images" :key="index">
          <el-image fit="cover" :src="image" style="width: 100%; height: 100%;" />
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="main">
      <div class="date">
        <van-row type="flex">
          <van-col span="8" style="text-align: center;">
            <div class="arrival">
              <div>{{ $t('checkin') }}</div>
              <div @click="showDate = true">{{ arrival | formatDate }}</div>
            </div>
          </van-col>
          <van-col span="8" style="display: flex; flex-direction: row;  align-items: center; justify-content: center;">
            <div class="nights">
              {{ nights }} {{ $t('nights') }}
            </div>
          </van-col>
          <van-col span="8" style="text-align: center;">
            <div class="departure">
              <div>{{ $t('checkout') }}</div>
              <div @click="showDate = true">{{ departure | formatDate }}</div>
            </div>
          </van-col>
        </van-row>
        <van-calendar
          v-model="showDate" type="range"
          :formatter="formatter"
          :min-date="minDate"
          :max-date="maxDate"
          :default-date="[arrival, departure]"
          @confirm="onConfirm"
        />
      </div>

      <van-divider />

      <div class="guests">
        <van-row type="flex">
          <van-col span="12">{{ $t('guests') }}</van-col>
          <van-col span="12" style="display: flex; flex-direction: row; justify-content: flex-end;">
            <van-stepper v-model="guests" integer :min="1" :max="10" />
          </van-col>
        </van-row>
      </div>

      <div class="search">
        <van-button type="warning" block style="border-radius: 6px;" @click="search">{{ $t('search') }}</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from '@/utils/dayjs'
import { Notify } from 'vant'
import Header from '@/components/Header'
import { formatDate } from '@/filters'
import { getSystemDate } from '@/api/system'

export default {
  name: 'Search',
  components: {
    Header
  },
  data () {
    const today = dayjs().startOf('date').form('YYYY-MM-DD')
    return {
      showDate: false,
      today,
      arrival: today,
      departure: today,
      guests: 1,
      minDate: today,
      maxDate: today,
      images: [
        '/server-static/25591611806001.png',
        '/server-static/25621611806002.png',
        '/server-static/25641611806004.png'
      ]
    }
  },
  async mounted () {
    // 获取可预订的范围
    try {
      const { data: serverToday } = await getSystemDate({ format: 'DATE' })
      this.today = serverToday
      const res = await this.$axios.get('/invitation/reservation-dates')
      this.minDate = res.data.reservation_begin_date < this.today ? this.today : res.data.reservation_begin_date
      this.maxDate = res.data.reservation_end_date

      this.arrival = this.minDate
      const nextDay = new Date(this.arrival.getTime() + 86400000)
      this.departure = nextDay <= this.maxDate ? nextDay : this.maxDate
    } catch (e) {
      Notify(e.message)
    }
  },
  computed: {
    nights () {
      return ((this.departure - this.arrival) / 86400000).toFixed(0)
    }
  },
  methods: {
    formatter (day) {
      if (day.type === 'start') {
        day.bottomInfo = this.$t('in')
      } else if (day.type === 'end') {
        day.bottomInfo = this.$t('out')
      }

      return day
    },
    onConfirm (e) {
      this.arrival = e[0]
      this.departure = e[1]
      this.showDate = false
    },
    search () {
      this.$router.push({
        name: 'HotelList',
        params: { arrival: formatDate(this.arrival), departure: formatDate(this.departure), guests: this.guests }
      })
    }
  }
}
</script>

<style lang="less">
.main {
  width: 1200px;
  margin: 0 auto;
}

.date {
  padding: 20px 10px;

  .nights {
    border: 1px solid #CCCCCC;
    padding: 5px 10px;
    border-radius: 50%;
    color: #7C7C7C;
  }
}

.guests {
  .van-row {
    padding-top: 10px;
  }
  padding: 0 10px 10px 10px;
}

.search  {
  padding: 20px 10px;
}
</style>
